$(function () {

    //выцепляем яблоки, вешаем класс
    if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
        $("body").addClass("ios");
    }


    //запускаем фоновый видос
    var video = document.getElementsByClassName('bg_video');

    if (video.length) {
        video = video[0];
        if (!$("body").hasClass("mz-touchevents")) {
            $(video).html('<source src="/static/video/zmis_video.mp4" type="video/mp4">');
            setTimeout(function () {
                video.play();
            }, 100);
        }
    }

    //главный слайдер
    var $mainSlider = $(".main_slider");

    if ($mainSlider.length) {
        $mainSlider.flickity({
            pageDots: false,
            prevNextButtons: false
        });
        var flkty = $mainSlider.data("flickity");
        //создаем навигацию для слайдов
        var $galleryNavItems = $(".wrapper--slider .flicker__btn");
        var animated = [0];

        $mainSlider.on("select.flickity", function () {
            $galleryNavItems.removeClass("is-selected");
            $galleryNavItems.eq(flkty.selectedIndex).addClass("is-selected");
            //если слайд уже показывался, то анимация текста не выполняется
            if (animated.indexOf(flkty.selectedIndex) === -1) {
                $(".fade", flkty.selectedElement).map(function (i, item) {
                    stepAnimateText(item, "bounceInDown", 0.05, .5);
                });
                animated.push(flkty.selectedIndex);
                //добавляем классы для анимации текста и кнопок в слайдах
                $(".main_slider__slide_text, .main_slider__slide_btn", flkty.selectedElement).addClass("sliderFade");
            }

            //управляем воспроизведением видоса
            if (animated.indexOf(flkty.selectedIndex) == 0) {
                video.play();
            } else {
                video.pause();
            }

        });

        $galleryNavItems.map(function (i, item) {
            $(item).on("click", function () {
                $mainSlider.flickity("select", i);
            });
        });

        //анимация заголовоков слайдов
        function stepAnimateText(element, animation, stepDelay, delay) {
            var text = $(element).text();
            var curr = "";

            for (var i = 0; i < text.length; i++) {
                var character = text.charAt(i);
                var curDelay = (i * stepDelay) + delay;
                curr += '<span class="' + animation + '" style="-webkit-animation-delay: ' + curDelay + 's; animation-delay: ' + curDelay + 's">' + character + "</span>";
                $(element).html(curr);
            }
        }

        var forwardSlide = $(".fade");
        if (forwardSlide.length) {
            forwardSlide = forwardSlide[0];
            stepAnimateText(forwardSlide, "bounceInDown", 0.05, .5);
        }

        //анимация текста и кнопок в слайдах
        $(".main_slider__slide_text, .main_slider__slide_btn", flkty.selectedElement).addClass("sliderFade");
    }

    //слайдер на текстовых
    var $defaultSlider = $(".default_slider");

    if ($defaultSlider.length) {
        $defaultSlider.flickity({
            pageDots: false,
            prevNextButtons: true,
            imagesLoaded: true
        });
    }

    //галереи
    var $gallery = $(".gallery");

    if ($gallery.length) {
        $gallery.lightGallery({
            selector: "a"
        });
    }

    //мобильное меню
    $('.header__menu_btn').click(function () {
        $('.header__menu').addClass('header__menu--opened');
        $.lockBody();
    });

    $('.header__menu_close').click(function () {
        $('.header__menu').removeClass('header__menu--opened');
        $.unlockBody();
    });

    //закрываем элементы по escape
    $(document).keyup(function (event) {
        if (event.which == '27') {
            $(".header__menu").removeClass("header__menu--opened");
            $(".form__complete").removeClass("form__complete--visible");
            $.unlockBody();
        }
    });

    //лочим скролл страницы при открытых модалках и бургере
    $.lockBody = function () {
        var scrollBarWidth = window.innerWidth - document.body.offsetWidth;
        document.body.style.margin = '0px ' + scrollBarWidth + 'px 0px 0px';
        document.body.style.overflow = 'hidden';
    };

    $.unlockBody = function () {
        document.body.style.margin = '';
        document.body.style.overflow = '';
    };

    //поля форм с подсказками
    if ($(".js_field").length) {

        var $inputs = $("input", ".js_field");
        var $textareas = $("textarea", ".js_field");

        var fn = function () {
            if ($(this).val().trim() !== "") {
                $(this).closest(".form__group").addClass("form__group_filled");
            }

            $(this).on("focus", function () {
                $(this).closest(".form__group").addClass("form__group_filled");
            });

            $(this).on("blur", function () {
                if ($(this).val().trim() === "")
                    $(this).closest(".form__group").removeClass("form__group_filled");
            });
        };

        $inputs.each(fn);

        $textareas.each(fn);
    }

    //закрываем алерт после успешной отправки формы
    $(".form__complete_close, .form__complete_good").click(function () {
        $(".form__complete").removeClass("form__complete--visible");
    });

    $('.js-form').each(function (i, item) {
        var form = new TurboForm(item, {
            errorMsgClass: 'form__group--error',
            fieldLineClass: 'form__group',
            messageBoxSelector: '.form__complete',
            showErrorAfterInput: true,
            unholdFormAfterSuccess: true
        });

        var uploadContainer = item.querySelector('.upload');
        if (uploadContainer) {
            var uploader = new TurboUploader(uploadContainer);
        }

        form.showErrorForInput = function (container, input, filedName) {
            container.classList.add(this.errorMsgClass);
            var msg = container.querySelector('.form__error_text');
            if (msg) {
                msg.textContent = typeof this.errors[filedName] === "object" ? this.errors[filedName].message : this.errors[filedName];
            } else {
                alert(typeof this.errors[filedName] === "object" ? this.errors[filedName].message : this.errors[filedName]);
            }
        };

        form.hideErrorsForInputs = function () {
            var labels = this.form.querySelectorAll('.' + this.errorMsgClass),
                self = this;
            labels.forEach(function (item) {
                item.classList.remove(self.errorMsgClass);
            });
        };

        form.showSuccessMessage = function (message) {
            var self = this;
            if (this.messageBox) {
                var box = this.messageBox.querySelector('.form__complete_text');
                if (box) {
                    if (message) {
                        box.innerHTML = message;
                    } else {
                        box.textContent = 'Сообщение успешно отправлено';
                    }
                }
                this.messageBox.classList.add('form__complete--visible');
                $(this.messageBox)
                    .find('.form__complete_good, .form__complete_close')
                    .one('click', function (e) {
                        self.reset();
                        if (uploader) {
                            uploader.reset();
                        }
                    });
            }
        };

        form.reset = function () {
            this.unHoldForm();
            this.hidePreloader();
            this.hideOldErrors();
            if (this.errorBox) {
                this.errorBox.style.display = 'none';
            }
            this.form.reset();
        };
    });

});
